<template>
  <div class="manage-comments" :class="{skeleton:!state.loaded}">
    <div class="wrapper" v-if="state.comments.length">
      <Comment
          v-for="(c, idx) in state.comments" :key="`${c.createDate}${c.memberSeq}${idx}`"
          :content="c.content"
          :commentBlindSeq="c.commentBlindSeq"
          :createDate="c.createDate"
          :depth="Number(c.depth)"
          :edit="edit"
          :hide="hide"
          :idx="idx"
          :memberSeq="c.memberSeq || c.createId"
          :memberName="c.memberName"
          :memberAuth="c.memberAuth"
          :page="page"
          :remove="remove"
          :reply="reply"
          :restore="restore"
          :restoreFlag="c.restoreFlag"
          :skeleton="!state.loaded"
          :openId="openId"
          :totalAmt="c.totalAmt"
      />
      <div class="page">
        <Pagination :info="state.paginationInfo" :change="changePage" v-if="state.paginationInfo"/>
      </div>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive, ref, watch} from "@vue/composition-api";
import http from "@/scripts/http";
import Pagination from "@/components/Pagination.vue";
import mixin from "@/scripts/mixin";
import router from "@/scripts/router";
import MemberPicture from "@/components/MemberPicture";
import NoData from "@/components/NoData";
import Comment from "@/components/Comment";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageManageComments";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Comment, NoData, MemberPicture, Pagination},
  props: {
    openId: String,
    page: String,
  },
  setup(props) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 30
      },
      paginationInfo: null,
      comments: [],
    });

    const commentTextareaRef = ref();

    const projectType = router.app.$route.params.projectType;
    const projectSeq = Number(router.app.$route.params.projectSeq);

    const load = (pageIndex, preventScroll) => {
      state.pageIndex = pageIndex || window.Number(router.app.$route.query.page?.toString()) || 1;

      !preventScroll && window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });

      state.loaded = false;
      state.args.pageIndex = state.pageIndex;

      for (let i = 0; i < 5; i += 1) {
        state.comments.push({
          content: "Please wait a moment. Please wait a moment.",
          createDate: "0000-00-00 00:00",
          memberName: "Wait",
          children: []
        });
      }

      http.get(`/api/maker/project/${projectType}/${projectSeq}/${props.page}`, state.args).then(({data}) => {
        state.loaded = true;
        state.comments = data.body.list;

        // 후원자 응원글인 경우에는 API ResponseDTO가 다르다.
        if (props.page === "cheer") {
          state.comments.forEach(e => {
            e.groupNum = e.investorSeq;
          });
        }

        state.paginationInfo = data.body.paginationInfo;
      });
    };

    const reply = (idx) => {
      const comment = state.comments[idx];
      const parentComment = state.comments.find(c => c.groupNum?.toString() === comment.groupNum?.toString() && c.depth?.toString() === "0");

      const params = {
        groupNum: comment.groupNum,
      };

      (parentComment.createId || parentComment.memberSeq) && (params.parentWriterSeq = (parentComment.createId || parentComment.memberSeq));
      comment.investorSeq && (params.investorSeq = comment.investorSeq);

      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "답변하기",
          ...params,
        },
        callback: `${component.name}.submit`
      });
    };

    const edit = (idx) => {
      const comment = state.comments[idx];

      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "수정하기",
          commentSeq: comment.commentSeq,
          updateId: comment.memberSeq,
          content: comment.content
        },
        callback: `${component.name}.put`,
      });
    };

    const remove = (idx) => {
      const commentSeq = state.comments[idx].commentSeq;

      store.commit("confirm", {
        message: "선택하신 항목을 삭제하시겠습니까?",
        allow() {
          http.delete(`/api/maker/project/${projectType}/${projectSeq}/${props.page}/${commentSeq}/reply`).then(() => {
            store.commit("setSwingMessage", "선택하신 항목을 삭제하였습니다.");
            load();
          });
        }
      });
    };

    const submit = (params) => {
      const args = {
        content: params.content,
        groupNum: params.groupNum,
        parentWriterSeq: params.parentWriterSeq,
      };

      if (props.page === "cheer") {
        args.investorSeq = params.groupNum;
      }

      http.post(`/api/maker/project/${projectType}/${projectSeq}/${props.page}/reply`, args).then(() => {
        store.commit("setSwingMessage", "답변을 등록하였습니다.");
        load(state.pageIndex, true);
      });
    };

    const put = ({commentSeq, content}) => {
      http.put(`/api/maker/project/${projectType}/${projectSeq}/${props.page}/${commentSeq}/reply`, {content}).then(() => {
        store.commit("closeModal", {name: "Comment"});
        load(state.args.pageIndex, true);
      }).catch(httpError());
    };

    const changePage = (page) => {
      router.push({
        query: {page}
      });
    };

    const hide = (idx) => {
      const comment = state.comments[idx];
      const args = {
        restoreFlag: comment.restoreFlag,
        commentBlindSeq: comment.commentBlindSeq,
      };

      store.commit("confirm", {
        message: "해당 응원글을 차단하시겠습니까?",
        subMessage: "내용이 '진행자에 의해 비공개 처리되었습니다.' 로 대체되어 표기됩니다.",
        allow() {
          http.put(`/api/maker/project/${props.page}/${comment.investorSeq}/blind`, args).then(() => {
            store.commit("setSwingMessage", "비공개 처리하였습니다.");
            load(state.args.pageIndex, true);
          });
        }
      });
    };

    const restore = (idx) => {
      const comment = state.comments[idx];
      const args = {
        commentBlindSeq: comment.commentBlindSeq,
        restoreFlag: comment.restoreFlag,
      };

      http.put(`/api/maker/project/${props.page}/${comment.commentBlindSeq}/restore`, args).then(() => {
        store.commit("setSwingMessage", "게시글을 복구하였습니다.");
        load(state.args.pageIndex, true);
      });
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (JSON.stringify(next) === JSON.stringify(prev) || (next.modals || prev.modals)) {
        return;
      }

      load();
    });

    return {
      component
      , state
      , commentTextareaRef
      , load
      , changePage
      , reply
      , edit
      , remove
      , hide
      , restore
      , submit
      , put
    };
  },
});
</script>

<style lang="scss" scoped>
.manage-comments {
  color: $colorTextDefault;
  padding-top: $px15;

  > .wrapper {
    .list {
      > ul > li {
        margin-bottom: $px30;

        > .wrapper {
          position: relative;
          padding-left: $px47;
          padding-right: $px47;

          .img {
            width: $px35;
            height: $px35;
            position: absolute;
            top: $px3;
            left: 0;
            border: $px1 solid #eee;
            border-radius: 50%;
          }

          .root {
            b {
              margin-right: $px6;
            }

            .actions {
              position: absolute;
              top: 0;
              right: 0;

              .btn {
                margin-left: $px4;
                margin-right: 0;
                width: $px40;
                padding-left: 0;
                padding-right: 0;
              }
            }
          }

          .content {
            margin-top: $px5;
            word-break: break-all;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &[data-depth="1"] {
          padding-left: $px47;
          position: relative;

          > .wrapper {
            padding-right: $px94;
          }

          &:before {
            content: " ";
            background-image: url(/assets/img/page.project.detail.comment.pipe.svg);
            background-size: 100% 100%;
            width: $px20;
            height: $px20;
            position: absolute;
            top: $px5;
            left: $px15;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .page {
      padding-top: $px5;
    }
  }

  &.skeleton {
    > .wrapper .list li {
      .img, .root b, .root > span, .root .actions .btn, .content > span {
        @include skeleton;
      }
    }
  }
}
</style>